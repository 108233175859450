<template lang="pug">
.reservationDetail
  PageHeader(
    :title="'預約單'"
    @toLastPage="toLastPage"
  )
  h3.reservationDetail__subtitle 預約時間: {{ reservationTime }}
  p.reservationDetail__location(
  ) 從: {{ onLocation }}
  p.reservationDetail__location(
  ) 到: {{ offLocation }}
  p.reservationDetail__location(
  ) 乘客備註: {{ passengerNote }}
  .reservationDetail__panel(v-if='orderStatus === 1 || orderStatus === 2')
    h3.reservationDetail__subtitle.reservationDetail__subtitle--panel 已選擇:
    TaxiInfoPanel.reservationDetail__infoPanel(
      :driverImg="driverInfo.driverImg"
      :driverStar="driverInfo.driverStar"
      :driverNote="driverInfo.driverNote"
      :carLicense="driverInfo.carLicense"
      :carBrand="driverInfo.carBrand"
      :callNumber="driverInfo.callNumber"
      :teamName="teamInfo.teamName"
    )
    ReservationSuccessPanel.reservationDetail__successPanel(
      v-if='orderStatus === 1'
      @calldriverPhone="calldriverPhone"
      @callCustomerPhone="callCustomerPhone"
      @cancelReservation="openReservationModal"
      @showDriverLocation="showDriverLocation"
      @mobilePay="mobilePay"
    )
  .reservationDetail__description(v-if='orderStatus === 0 && driversList.length === 0')
    .reservationDetail__waiting
  .reservationDetail__selectorWrapper(
    v-if='orderStatus === 0'
  )
    DriverSelector.reservationDetail__selector(
      :driversList="driversList"
      :text="'確定'"
      @driverConfirmed="driverConfirmHandler"
    )
  .reservationDetail__footer(v-if='orderStatus === 0')
    button.reservationDetail__btn(
      @click="openReservationModal"
    ) 取消預約
  ReservationDriverConfirmModal(
    v-if="showConfirmModal"
    :driverImg="selectedDriver.photo"
    :driverStar="selectedDriver.reputation"
    :driverNote="selectedDriver.driverNote"
    :carLicense="selectedDriver.licence"
    :carBrand="selectedDriver.carBrand"
    :callNumber="selectedDriver.callNumber"
    :teamName="selectedDriver.teamName"
    @confirm="sendSelectedDriver"
    @cancel="closeConfirmModal"
  )
  BaseModal(
    v-if="showNotFoundModal"
    :title="'預約單取消'"
    :text="'預約單逾時未選司機，已幫您取消'"
    @confirm="closeNotFoundModal"
  )
  BaseModal(
    v-if="showText"
    :title="'預約單'"
    :text="'預約單取消失敗，可撥打客服或司機取消訂單'"
    @confirm="closeTextModal"
  )
  BaseConfirmModal(
    v-if="showReservationModal"
    :title="'是否取消預約單'"
    :confirmBtnText="'確定'"
    :cancelBtnText="'取消'"
    @cancel="closeReservationModal"
    @confirm="cancelReservation"
  )
  PaymentInfoModal(
    v-if="isShowPaymentInfoModal"
    :code="teamInfo.teamCode"
    :callNumber="driverInfo.callNumber"
    @confirmPrice="confirmPrice"
  )
  BillModal(
    v-if="isShowBillModal"
    :driverInfo="`${driverInfo.name}-${driverInfo.callNumber}`"
    :price="price"
    :onLocation="onLocation"
    :offLocation="offLocation"
    @creditCard="creditCard"
    @linePay="linePay"
  )
  BaseModal(
    v-if="isShowResult"
    :title="titleModal"
    :text="textModal"
    @confirm="confirmResult"
  )
</template>
<script>
import PageHeader from '@/components/common/PageHeader';
import DriverSelector from '@/components/DriverSelector';
import ReservationSuccessPanel from '@/components/ReservationSuccessPanel';
import ReservationDriverConfirmModal from '@/components/ReservationDriverConfirmModal';
import TaxiInfoPanel from '@/components/common/TaxiInfoPanel';
import BaseModal from '@/components/BaseComponent/BaseModal';
import BaseConfirmModal from '@/components/BaseComponent/BaseConfirmModal';

import { getDate, getTimeString, format, chineseDayConverter } from '@/utils/timeUtils';
import { pushLineMessage } from '@/api/userInfoService';
import Loading from '@/components/common/Loading';
import PaymentInfoModal from '@/components/PaymentInfoModal';
import BillModal from '@/components/BillModal';
import { creditCardPay, linePay } from '@/api/mobilePay';
import * as jsCookie from 'js-cookie'

import moment from 'moment'
import {
  putReservationDriver,
  putCancelReservation,
  getReservationDetail,
  getReservationDriverList,
} from '@/api/reservationService';

export default {
  name: 'ReservationDetail',
  data() {
    return {
      isDone: false,
      showConfirmModal: false,
      reservationId: '',
      reservationTime: '',
      deadlineTime: '',
      driversList: [],
      time: null,
      offLocation: null,
      onLocation: null,
      passengerNote: null,
      driverInfo:{
        driverImg: null,
        driverStar: null,
        driverNote: null,
        carLicense: null,
        carBrand: null,
        callNumber: null,
        driverPhone: null
      },
      teamInfo:{
        teamName: null,
        teamPhone: null
      },
      delay: 15000,
      fetchingTimeout: null,
      selectedDriverId: null,
      showNotFoundModal: false,
      showReservationModal: false,
      showText: false,
      isShowBillModal: false,
      isShowPaymentInfoModal: false,
      price: 0,
      titleModal: '付款成功',
      textModal: '',
      isShowResult: false
    };
  },
  computed: {
    selectedDriver() {
      return this.selectedDriverId === null
        ? null
        : this.driversList
            .filter(({ id }) => {return id === this.selectedDriverId})
            .pop()
    },
    orderStatus(){
      // 預約時間過: 2 ; 預約完成: 1 ; 未預約: 0
      //console.log('time', this.time)

      if(this.time === null){
         return 3
      }
      let current = moment()
      let reservation = moment(this.time).add(2, 'hour')
      //console.log('current.isAfter(reservation)', current.isAfter(reservation))

      if(current.isAfter(reservation)){
        return 2
      }
      //console.log('this.isDone', this.isDone)

      if(this.isDone) {
        return 1
      }
      return 0
    }
  },
  methods: {
    async linePay(){
      TPDirect.linePay.getPrime(async(result) => {
        //console.log("result : " , result)
        //console.log(jsCookie.get("lineId"))
        let object ={
          orderId: this.reservationId,
          classType: 'reservation',
          amount: this.price,
          prime: result.prime,
          lineId: jsCookie.get("lineId")
        }
        let linePayResult = await linePay(object)
        //console.log(linePayResult)
        if(linePayResult.data.success){
          //console.log(linePayResult.data.result)
          if(linePayResult.data.result === '已完成交易'){
            this.isShowBillModal = false
            this.textModal = linePayResult.data.result
            this.titleModal = '付款訊息'
            this.isShowResult = true
            return
          }
          TPDirect.redirect(linePayResult.data.result.paymentUrl)
          return
        }
        this.textModal = result.data.result
        this.titleModal = '付款訊息'
        this.isShowResult = true

      })
    },
    confirmResult(){
      this.isShowResult = false
    },
    async creditCard(){
      let object ={
        orderId: this.reservationId,
        classType: 'reservation',
        amount: this.price,
        lineId: jsCookie.get("lineId")
      }
      let result = await creditCardPay(object)
      this.isShowBillModal = false
      if(result.data.result === '信用卡付款成功'){
        this.textModal = result.data.result
        this.titleModal = '付款訊息'
      }else{
        this.textModal = result.data.result
        this.titleModal = '付款訊息'
      }
      this.isShowResult = true

    },
    confirmPrice(price){
      this.isShowBillModal = true
      this.isShowPaymentInfoModal = false
      this.price = price
    },
    mobilePay(){
      this.isShowPaymentInfoModal = true
    },
    calldriverPhone(){
      if(this.driverInfo.driverPhone === null) return alert('目前無提供電話號碼')
      window.location.href=`tel:${this.driverInfo.driverPhone}`;

    },
    callCustomerPhone(){
      if(this.teamInfo.teamPhone === null) return alert('目前無提供電話號碼')
      window.location.href=`tel:${this.teamInfo.teamPhone}`;
    },
    initReservationId() {
      this.reservationId = `${this.$route.query.id}`;
    },
    toLastPage() {
      this.$router.back();
    },
    setDriverList(list) {
      this.driversList = [...list];
    },
    setSelectedDriverId(id) {
      this.selectedDriverId = id;
    },
    openConfirmModal() {
      this.showConfirmModal = true;
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    driverConfirmHandler(id) {
      this.setSelectedDriverId(id);
      this.openConfirmModal();
    },
    setReservationTime(time) {
      let day = moment(time).get('day')
      let meridiemString =  moment(time).get('hour') < 12 ? '上午': '下午';
      this.reservationTime = `${getDate(time)} (${chineseDayConverter(day)}) ${meridiemString}${getTimeString(time)}`;
    },
    setDeadlineTime(createTime, reservationTime) {
      // 建立時間的後三十分
      // 預約時間的前30分
      // 預約時間 - 30  =< 建立時間 + 30 => 預約前 - 20分鐘要找到車
      // 預約時間 - 30 > 建立時間 + 30 => 建立時間 + 30

      // 目前時間到在通知取消。
      this.deadlineTime = reservationTime;
      return
      let reservationTimeSubtract =  moment(reservationTime).subtract(30, 'minutes')
      let createAdd = moment(createTime).add(30, 'minutes')
      let deadline = ''
      if(reservationTimeSubtract.isSame(createAdd)){
        deadline = moment(reservationTime).subtract(20, 'minutes').format('YYYY-MM-DD HH:mm:ss');
      }
      if(reservationTimeSubtract.isBefore(createAdd)){
        deadline = moment(reservationTime).subtract(20, 'minutes').format('YYYY-MM-DD HH:mm:ss');
      }
      if(reservationTimeSubtract.isAfter(createAdd)){
        deadline = moment(createTime).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss');
      }
      this.deadlineTime = deadline;
    },
    async fetchReservationDetail(reservationId) {
      try {
        const { data } = await getReservationDetail(reservationId);
        const { billInfo} = data.result;
        const { createTime, reservationTime, offLocation, onLocation, passengerNote } = billInfo;
        this.onLocation = onLocation
        this.offLocation = offLocation
        this.passengerNote = passengerNote
        this.isDone = billInfo.rideStatus === '預約成功' ||  billInfo.rideStatus === '已完成'

        if(this.isDone){
          this.driverInfo = data.result.driverInfo
          this.teamInfo = data.result.teamInfo
        }
        this.time = reservationTime
        this.setReservationTime(reservationTime);
        this.setDeadlineTime(createTime, reservationTime);
        this.fetchDrivers(this.reservationId);
      } catch (err) {
        console.error(err);
      }
    },
    async fetchDrivers(reservationId) {
      let deadline = moment(this.deadlineTime)
      let current = moment()
        if(!this.isDone && current.isAfter(deadline)){
            this.openNotFoundModal()
          return
        }
      try {
        const { data } = await getReservationDriverList(reservationId);
        const { result } = data;
        this.setDriverList(result.list);
      } catch(err) {
        console.error(err);
      }

      this.fetchingTimeout = setTimeout(() => {
        this.fetchDrivers(reservationId);
      }, this.delay);
    },
    async sendSelectedDriver() {
      const {
        id, teamId, driverCarStateId
      } = this.selectedDriver;
      try {
        let result = await putReservationDriver(
          this.reservationId,
          { driverId: id, teamId, driverCarStateId },
        );
        if(result.data.result === '配對成功'){
          let payload = {
          message: `預約時間: ${this.reservationTime}，預約成功，請到歷史紀錄內查詢`,
          type: 0
          }
          let pushMessage = await pushLineMessage(payload)
          this.toLastPage();
          return
        }
        this.showText = true
      } catch(err) {
        console.error(err);
        this.toLastPage();
      }
    },
    async cancelReservation() {
      this.closeReservationModal()
      let result = await putCancelReservation(this.reservationId);
      //console.log(result.data.result)
      if(result.data.result === '取消成功'){
        this.showNotFoundModal = false
        this.$router.go(-1)
        return
      }
      this.showText = true
    },
    closeNotFoundModal(){
      this.cancelReservation()
    },
    openNotFoundModal(){
       this.showNotFoundModal = true
    },
    closeReservationModal(){
      this.showReservationModal = false
    },
    closeTextModal(){
      this.showText = false
    },
    openReservationModal(){
      this.showReservationModal = true
    },
    showDriverLocation(){
      this.$router.replace({ path: 'TaxiStatus', query: { task: 'reservation', id: this.reservationId } })
    }
  },
  components: {
    PageHeader,
    DriverSelector,
    ReservationDriverConfirmModal,
    TaxiInfoPanel,
    BaseModal,
    ReservationSuccessPanel,
    BaseConfirmModal,
    PaymentInfoModal,
    BillModal
  },
  async mounted() {
    await this.initReservationId();
    if(!this.isDone){
      await this.fetchReservationDetail(this.reservationId);
    }
  },
  beforeDestroy() {
    clearTimeout(this.fetchingTimeout);
  },
};
</script>
<style lang="scss" scoped>
  .reservationDetail {
    position: relative;
    width: 100vw;
    height: 100vh;

    &__panel {
      margin-top: 24px;
      padding: 0 16px;
    }

    &__infoPanel {
      border-radius: 20px;
      border: solid 2px #efeeea;
    }

    &__successPanel {
      margin-top: 24px;
    }

    &__subtitle {
      margin: 0;
      margin-bottom: 4px;
      margin-top: 16px;
      padding: 0 24px;
      height: 20px;
      line-height: 20px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);

      &--panel {
        padding: 0;
      }
    }

    &__location {
      margin: 0;
      margin-top: 5px;
      padding: 0 24px;
      font-size: 16px;
      color: rgba(141, 141, 141, 0.87);
    }

    &__description {
      padding-top: 100px;
      box-sizing: border-box;
    }

    &__waiting {
      margin: 0 auto;
      width: 200px;
      height: 100px;
      background-image: url('../assets/images/waiting.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &__text {
      margin: 0;
      height: 16px;
      line-height: 16px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.87);

      &--info {
        margin-top: 8px;
        color: #767676;
      }
    }

    &__selectorWrapper {
      margin-top: 10px;
      height: calc(100vh - 192px);
      overflow-y: auto;
      box-sizing: border-box;
    }

    &__call {
      width: 30%;
      text-align: center;
      outline: none;
      border: none;
      height: 32px;
      line-height: 32px;
      background-color: #ffffff;
      color: green;
      font-size: 16px;
      font-weight: 500;
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      bottom: 0;
      padding: 10px;
      width: 100%;
      height: 70px;
      box-sizing: border-box;
      background-color: #767676;
    }

    &__btn {
      padding: 0;
      width: 120px;
      text-align: center;
      outline: none;
      border: none;
      border-radius: 10px;
      height: 50px;
      line-height: 32px;
      background-color: #c33303;
      color: #ffffff;
      font-size: 20px;
      letter-spacing: 4px;
    }
    
  }
</style>
