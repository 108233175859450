export const extraServiceOptions = [
  { id: 1, name: "寵物友善" },
  { id: 2, name: "可載輪椅" },
  { id: 3, name: "無檳榔/無菸" },
  { id: 4, name: "日語" },
  { id: 5, name: "英語" },
  { id: 6, name: "無障礙車" },
  { id: 7, name: "代客接電" },
  { id: 21, name: "五年新車" },
  { id: 8, name: "休旅車" }
];

// 大豐車隊目前只可以使用現金，其餘車隊可使用三種方式付款
let options = [];
if (document.URL.indexOf("dihon.line.gladmobile.com") > -1) {
  options = [{ value: 0, description: "現金" }];
} else {
  options = [
    { value: 0, description: "現金" },
    { value: 1, description: "Line Pay" },
    /* { value: 2, description: "信用卡" } */
  ];
}
export const paymentOptions = options;
